import { CateringTableConfig } from '../../../core-lib/models/catering-head.model';
import { PageableModel } from '../../../core-lib/models/pageable.model';
import { ProposalHeadModel } from '../../../core-lib/models/proposal-head.model';
import { TableColumnConfigModel } from '../../../core-lib/models/table-column-config.model';
import { CateringTableSelectionModel } from '../../models/table-selection.model';

// TODO: ProposalHeadModel -> CateringHeadModel
export interface CateringTableState extends CateringTableSelectionModel<PageableModel<ProposalHeadModel>> {
  columnConfig: CateringTableSelectionModel<TableColumnConfigModel[]>;
  filterConfig: CateringTableSelectionModel<TableColumnConfigModel[]>;
  loading: boolean;
}

const emptyPage: PageableModel<any> = {
  content: [],
  totalPages: 0,
  totalElements: 0,
  size: 20,
  number: 0,
};

const defaultTableConfig: CateringTableConfig[] = [
  {
    columnName: 'actions',
    visible: true,
    position: 0,
  }, {
    columnName: 'publicId',
    visible: true,
    position: 1,
  }, {
    columnName: 'state',
    visible: true,
    position: 2,
  }, {
    columnName: 'startDateTime',
    visible: true,
    position: 3,
  }, {
    columnName: 'endDateTime',
    visible: true,
    position: 4,
  }, {
    columnName: 'locationInfo',
    visible: true,
    position: 5,
  }, {
    columnName: 'room',
    visible: true,
    position: 6,
  }, {
    columnName: 'participantCount',
    visible: true,
    position: 7,
  }, {
    columnName: 'collectionByAuthor',
    visible: true,
    position: 8,
  },
];

export const initialCateringTableState: CateringTableState = {
  columnConfig: {
    FINISHED: defaultTableConfig,
    IN_PROGRESS: defaultTableConfig,
    DEPUTY: defaultTableConfig,
    PENDING: defaultTableConfig,
  },
  filterConfig: {
    FINISHED: [],
    IN_PROGRESS: [],
    DEPUTY: [],
    PENDING: [],
  },
  loading: false,
  PENDING: emptyPage,
  IN_PROGRESS: emptyPage,
  DEPUTY: emptyPage,
  FINISHED: emptyPage,
};
